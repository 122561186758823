import React from "react"
import { Link } from "gatsby"
import { Text } from "theme-ui"
import handleReferenceLink from "./functions/handleReferenceLink"
import { trackNavigationItemClicked } from "../services/analytics"
import { nextJsPages } from "../components/v2/Link"

const renderNavigationItem = ({
  name,
  item,
  locale,
  menuType,
  internalLinkOnClick,
  highlightLabel,
  highlightColor,
}: {
  name: string
  item: any
  locale: string
  menuType: "desktop" | "mobile" | "footer"
  internalLinkOnClick?: () => void
  highlightLabel?: string
  highlightColor?: string
}) => {
  if (!item || (!item.linkTo && !item.reference)) {
    return (
      <span>
        {name}
        {Boolean(highlightLabel) && (
          <Text
            sx={{
              fontSize: "xxs",
              color: Boolean(highlightColor) && highlightColor,
              position: "relative",
              top: "-6px",
              left: "4px",
              fontWeight: "bold",
            }}
          >
            {highlightLabel}
          </Text>
        )}
      </span>
    )
  }

  const link =
    item.linkTo?.indexOf("https") === 0
      ? item.linkTo
      : handleReferenceLink({
          reference: item.reference,
          locale: locale,
          linkUrl: item.linkTo,
        })

  if (item.linkTo?.indexOf("https") !== 0 && nextJsPages.includes(link)) {
    return (
      <a href={link}>
        {name}
        {Boolean(highlightLabel) && (
          <Text
            sx={{
              fontSize: "xxs",
              color: Boolean(highlightColor) && highlightColor,
              position: "relative",
              top: "-6px",
              left: "2px",
              fontWeight: "bold",
            }}
          >
            {highlightLabel}
          </Text>
        )}
      </a>
    )
  }

  return item.linkTo?.indexOf("https") === 0 ? (
    <a href={link} rel="noreferrer" target="_blank">
      {name}
      {Boolean(highlightLabel) && (
        <Text
          sx={{
            fontSize: "xxs",
            color: Boolean(highlightColor) && highlightColor,
            position: "relative",
            top: "-6px",
            left: "2px",
            fontWeight: "bold",
          }}
        >
          {highlightLabel}
        </Text>
      )}
    </a>
  ) : (
    <Link
      onClick={() => {
        internalLinkOnClick && internalLinkOnClick()
        trackNavigationItemClicked({
          name: name,
          url: link,
          menuType: menuType,
        })
      }}
      to={link}
    >
      {name}
      {Boolean(highlightLabel) && (
        <Text
          sx={{
            fontSize: "xxs",
            color: Boolean(highlightColor) && highlightColor,
            position: "relative",
            top: "-6px",
            left: "4px",
            fontWeight: "bold",
          }}
        >
          {highlightLabel}
        </Text>
      )}
    </Link>
  )
}

export default renderNavigationItem
